<script setup>

</script>

<template>
  <div class="h-full">
    <slot />
  </div>
</template>
<style scoped>

</style>
